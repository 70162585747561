<template>
	<HomeTamplate>
		<div class="columns flex-wrap">
			<div class="column is-12 " :class="maxWidth > 1170 ? 'has-text-right':'has-text-centered'">
				<a :href="webAvocado"><img src="@/assets/logoAvocado.png" /></a>
			</div>
			<div class="column has-text-left is-9 mg-x-auto">
				<b-notification
		            auto-close 
		            type="is-danger"
		            :active.sync="isActive"
		            :duration="5000"
		            aria-close-label="Close notification">
		            {{msgErrorValid}}
		        </b-notification>
				<h1 class="is-size-3 has-text-centered mg-b-5 text-color-primary">กรุณากรอกรหัสผ่านใหม่</h1>
				<form @submit.prevent="reset()">
					<b-field label="รหัสผ่านใหม่" class="mg-b-5">
			            <b-input rounded v-model="form.password" type="password" password-reveal></b-input>
			        </b-field>
			        <b-field label="ยืนยันรหัสผ่านใหม่" class="mg-b-5">
			            <b-input rounded v-model="form.rePassword" type="password" password-reveal></b-input>
			        </b-field>
			        <div class="column is-6  mg-y-5 mg-x-auto ">
			        	<div class="buttons">
			        		<button type="submit" class="pd-y-5 bt-color-avocado is-rounded w-100 button is-info" rounded expanded>เปลี่ยนรหัสผ่าน</button>
			        	</div>
			        </div>
			    </form>
			</div>
		</div>
	</HomeTamplate>
</template>
<script>
import {HTTP} from '@/service/axios'
import HomeTamplate from '@/template/HomeTemplate'
	export default{
		props: {
		    token: {
		      type: Object,
		      default: null
		    },
		},
		components:{
			HomeTamplate
		},
		mounted(){
			this.token != null ? this.form.code = this.token.code : null
			this.checkCode()
		},
		data(){
			return {
				isActive:false,
				form:{
					email:'',
					password:'',
					rePassword:'',
					code:''
				},
				maxWidth: window.innerWidth,
				msgErrorValid:'',
				windowHeight:window.innerHeight,
				webAvocado: process.env.VUE_APP_WEB_AVOCADO,
			}
		},
		methods:{
			checkCode(){
				HTTP.post('/check/reset',this.form).then(res=>{
					if(!res.data.success){
						this.$buefy.toast.open({
							duration: 5000,
		                    message: 'เซสชั่นหมดอายุ กรุณารีเซ็ตรหัสผ่านใหม่อีกครั้ง',
		                    type: 'is-warning'
		                })
		                this.$router.push("/forgot-password");
					}else{
						this.form.email = res.data.obj.email
					}
				})
			},
			reset(){
				if (this.form.password === "" || this.form.rePassword === "") {
					this.isActive = true
			        this.msgErrorValid = "กรุณากรอกรหัสผ่านและ ยืนยันรหัสผ่าน";
			        return;
			    } else if (this.form.password != this.form.rePassword) {
			    	this.isActive = true
			        this.msgErrorValid = "กรุณากรอกรหัสผ่านให้ตรงกัน";
			        return;
			    }
				HTTP.post('reset/password',this.form).then(res=>{
					if(res.data.success){
						this.$buefy.toast.open({
							duration: 5000,
		                    message: 'เปลี่ยนรหัสผ่านสำเร็จ กรุณาเข้าสู่ระบบ',
		                    type: 'is-success'
		                })
		                this.$router.push("/sign-in");
					}
				})
			}
		}
	}
</script>
<style scoped>
	
	
</style>